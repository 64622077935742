import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Bundle, CarePlan, Condition, FhirResource, Goal, Patient, Task } from 'fhir/r4';
import { catchError, combineLatest, EMPTY, map, Observable, Subject, switchMap, takeUntil, tap, timer } from 'rxjs';
import { ALERT_TITLES, API_POLLING_DURATION, OBSERVATION_CODES, defaultSummaryAlertCardValues, metricsFilters, summaryAlertsTableColumns, systemFilters } from 'src/app/config/app.config';
import { filterProgramNameFromCarePlan } from 'src/app/mappers/careplan.mapper';
import { filterDiagnosisName } from 'src/app/mappers/diagnosis.mapper';
import { GetPatientIdsAsArray, filterPatientNameFromPatient } from 'src/app/mappers/patient.mapper';
import { MapBundleToResourceArray, MapBundledRequestResponseToBundle } from 'src/app/mappers/shared.mapper';
import { AlertService } from 'src/app/services/alert.service';
import { CareplanService } from 'src/app/services/careplan.service';
import { PatientService } from 'src/app/services/patient.service';
import { IAlertsTableRowData } from 'src/app/shared/interfaces/alerts-table-row-data';

@Component({
  selector: 'dashboard-alerts-app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, OnDestroy {
  alerts = [...defaultSummaryAlertCardValues];

  unsubscribe$ = new Subject<void>();
  unsubscribeProgramSelection$!: Subject<void>;
  unsubscribeCardSelection$!: Subject<void>;

  selectedAlertType: string = ALERT_TITLES.ALL_ALERTS;

  systemColumns = systemFilters;
  metricsColumns = metricsFilters;
  systemColumnsForm!: FormGroup;
  metricsColumnsForm!: FormGroup;

  displayedColumns: Array<{ column: string; title: string }> = summaryAlertsTableColumns;

  /** Data passed to table to render */
  alertsTableData!: Array<IAlertsTableRowData>;

  /** To show spinner in table */
  showTableSpinner = true;

  /**
   * List of alerts (Task Resources array), used to filter 
   * the patient id, alert value and everything.
   */
  alertTasks: Array<Task> | null = null;

  /** To store the unique patients from the alert(Task) list 
   * because we cant get the patient ids uniquely from api for
   * patient with alert
  */
  uniquePatientsFromAlerts: Set<string> = new Set();

  /**
   * Used to store row data which will be later used 
   * to compare during rendering and data processing, 
   * and then later passed to material table.
   */
  rowData: Array<IAlertsTableRowData> = [];

  selectedProgram = '';

  constructor(
    private alertService: AlertService,
    private patientService: PatientService,
    private formBuilder: FormBuilder,
    private carePlanService: CareplanService,
  ) { }

  ngOnInit(): void {
    this.carePlanService.programSelectionChanged$
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe((selectedProgram: string) => {
        this.selectedProgram = selectedProgram;

        // unsubscribe program selection timer 
        if (this.unsubscribeProgramSelection$) {
          this.unsubscribeProgramSelection$.next();
          this.unsubscribeProgramSelection$.complete();
        }

        // unsubscribe card selection timer 
        if (this.unsubscribeCardSelection$) {
          this.unsubscribeCardSelection$.next();
          this.unsubscribeCardSelection$.complete();
        }

        // subscribe to program selection timer
        this.unsubscribeProgramSelection$ = new Subject<void>();
        this.populateAlertsData();
      });

    /** Create Form group for showing check boxes in system column filter
     *  form group with controls with name same as column name from `systemFilters`
     */
    this.systemColumnsForm = this.formBuilder.group(
      systemFilters.reduce((obj: any, item) => {
        obj[item.column] = true;
        return obj;
      }, {})
    );

    /**Hide the patientStatus column by default from the table */
    this.systemColumnsForm.controls['patientStatus'].setValue(false);

    /** Create Form group for showing check boxes in metric column filter
     *  form group with controls with name same as column name from `metricsFilters`
     */
    this.metricsColumnsForm = this.formBuilder.group(
      metricsFilters.reduce((obj: any, item) => {
        obj[item.column] = true;
        return obj;
      }, {})
    );

    /**
     * For column filter (system column filter) changes
     */
    this.systemColumnsForm.valueChanges
      .pipe(
        tap((values: any) => {
          /**
           * Filter the changed system columns
           */
          const activeSystems = Object.keys(values)
            .filter((item: any) => values[item]);
          const updatedSystemsFilters = systemFilters.filter((item: any) => {
            return activeSystems.find((key: any) => key === item.column)
          })

          /** Find the metric column values and set them too
           * inorder to show selected metrics columns along 
           * with the system columns
           */
          const activeMetrics = Object.keys(this.metricsColumnsForm.value)
            .filter((item: any) => this.metricsColumnsForm.value[item]);
          const updatedMetricsFilters = metricsFilters.filter((item: any) => {
            return activeMetrics.find((key: any) => key === item.column)
          })

          this.displayedColumns = [...updatedSystemsFilters, ...updatedMetricsFilters];
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe()

    /**
     * For column filter changes (metrics column filter changes)
     */
    this.metricsColumnsForm.valueChanges
      .pipe(
        tap((values: any) => {
          /**
           * Filter the changed metrics columns 
           */
          const activeMetrics = Object.keys(values)
            .filter((item: any) => values[item]);
          const updatedMetricFilters = metricsFilters.filter((item: any) => {
            return activeMetrics.find((key: any) => key === item.column)
          })

          /** Find the systemColumns values and set them too
           * inorder to show selected system columns along 
           * with the metric columns
           */
          const activeSystems = Object.keys(this.systemColumnsForm.value)
            .filter((item: any) => this.systemColumnsForm.value[item]);
          const updatedSystemsFilters = systemFilters.filter((item: any) => {
            return activeSystems.find((key: any) => key === item.column)
          })

          /** Set the new list of columns as displayed column to pass to table */
          this.displayedColumns = [...updatedSystemsFilters, ...updatedMetricFilters];
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe()
  }

  /**
   * Populates data 
   */
  populateAlertsData(): void {
    timer(0, API_POLLING_DURATION).pipe(
      switchMap(() => {
        // trigger fetch active users API
        this.carePlanService.fetchActiveUsers$.next();

        // reset alerts table data
        this.selectedAlertType = ALERT_TITLES.ALL_ALERTS;
        this.showTableSpinner = true;
        this.alertsTableData = [];
        this.rowData = [];

        return this.alertService.getAlertCount(1000, this.selectedProgram)
          .pipe(
            tap(this.setAlertTaskAndGenerateRows),
            map(this.alertService.filterTotalCount),
            tap(
              (totalAlertsCount) => {
                this.alerts[0].count = totalAlertsCount ? totalAlertsCount : this.alerts[0].count;
              }
            ),
            catchError(this.handleApiError)
          )
      }),
      takeUntil(this.unsubscribeProgramSelection$)
    ).subscribe();
  }

  /**
   * Takes the code and maps the value of corresponding alert
   * to the row data object which is passed to the table
   * @param row row object for row data array
   * @param code alert or task code
   * @param value value of the alert
   */
  mapValueToRowObj(row: IAlertsTableRowData, code: string, value?: string) {
    const numericValue = value?.split(' ')[0] ? Number(value?.split(' ')[0]) : null
    if (code === OBSERVATION_CODES.BLOOD_PRESSURE && !row.bloodPressure) {
      const alertUnitData = value?.split(' ')[2] ? Number(value?.split(' ')[2]) : null
      row.bloodPressure = numericValue;
      row.alertUnit = alertUnitData
    }
    if (code === OBSERVATION_CODES.SPO2 && !row.spo2) {
      row.spo2 = numericValue;
    }

    if (code === OBSERVATION_CODES.WEIGHT && !row.weight) {
      row.weight = numericValue;
    }
    if (code === OBSERVATION_CODES.HEART_RATE && !row.heartRate) {
      row.heartRate = numericValue;
    }
    if (code === OBSERVATION_CODES.TEMPERATURE && !row.temperature) {
      row.temperature = numericValue;
    }
    if (code === OBSERVATION_CODES.BLOOD_GLUCOSE && !row.bloodGlucose) {
      row.bloodGlucose = numericValue;
    }
  }

  /**
   * Based on the selected Alert title generate the list of unique patients
   * and find the latest alert of each type (like heart rate, blood pressure etc)
   * and then create a map to render in table
   */
  generateRowDataFromAlertTask = () => {
    if (
      this.selectedAlertType !== ALERT_TITLES.ADHERENCE_ALERTS
    ) {

      /**
       * Reset the uniquePatients list and row data ( data passed to table )
       */
      this.uniquePatientsFromAlerts = new Set();
      // this.rowData = [];
      const data: Array<IAlertsTableRowData> = [];

      /** Reset the count of each alerts */
      if (this.selectedAlertType === ALERT_TITLES.ALL_ALERTS || this.selectedAlertType === ALERT_TITLES.TOTAL_ALERTS) {
        this.alerts.forEach((alert: any) => { alert.count = 0 })
      }

      /**
       * 1. Filter unique patients
       */
      this.alertTasks?.forEach((entry: any) => {
        const alert = entry.resource as Task;
        const patientId = alert.for?.reference?.split("/")[1];
        if (patientId) {
          this.uniquePatientsFromAlerts.add(patientId);
        }

        if (this.selectedAlertType === ALERT_TITLES.ALL_ALERTS || this.selectedAlertType === ALERT_TITLES.TOTAL_ALERTS) {
          /**
           * Count Each type of alerts
           */
          const code = alert?.code?.coding?.[0]?.code || null;
          this.countAlerts(code);
        }

        /**
         * Find the table data and push the alert value to it
         */
        const rowIndex = data.findIndex((item: IAlertsTableRowData) => item.patientId === patientId);
        if (rowIndex > -1) {
          const row = { ...data[rowIndex] };
          const value = alert.input?.[0].type.coding?.find((code: any) => code.system === "Reported Value")?.display;
          this.mapValueToRowObj(row, alert.code?.coding?.[0]?.code || '', value)
          data[rowIndex] = row;
        }
        /**
         * row data does not exist for patient, add a new row data
         * and add the value
         */
        else {
          const row: any = { ...tableData[0] };
          row.patientId = patientId;
          const value = alert.input?.[0].type.coding?.find((code: any) => code.system === "Reported Value")?.display;
          this.mapValueToRowObj(row, alert.code?.coding?.[0]?.code || '', value)
          data.push(row);
        }
      });
      // R2-1036: show alerts for active patients only
      const patientIds = data
        .map((patient: IAlertsTableRowData) => patient.patientId)
        .filter(Boolean);
      this.patientService.getPatientsWithActiveCarePlans(patientIds).subscribe((getPatientsWithActiveCarePlansBundle: Bundle<FhirResource>) => {
        const activePatients: Patient[] = MapBundleToResourceArray<Patient>(MapBundledRequestResponseToBundle(getPatientsWithActiveCarePlansBundle));
        const activePatientIds: string[] = GetPatientIdsAsArray(activePatients);
        const activePatientsData = data.filter((patient: IAlertsTableRowData) => activePatientIds.includes(patient.patientId));
        this.fetchDataAndPushToTable(activePatientsData);
      });
    }
  }

  countAlerts = (code: string | null) => {
    const totalAlertsIndex = this.alerts.findIndex((item: any) => item.title === ALERT_TITLES.TOTAL_ALERTS);
    if (totalAlertsIndex >= 0) {
      this.alerts[totalAlertsIndex].count++;
    }

    if (code) {
      switch (code) {
        case OBSERVATION_CODES.HEART_RATE:
          const heartRateIndex = this.alerts.findIndex((item: any) => item.title === ALERT_TITLES.HEART_RATE_ALERTS);
          this.alerts[heartRateIndex].count++;
          break;
        case OBSERVATION_CODES.SPO2:
          const spo2Index = this.alerts.findIndex((item: any) => item.title === ALERT_TITLES.SPO2_ALERTS);
          this.alerts[spo2Index].count++;
          break;
        case OBSERVATION_CODES.BLOOD_PRESSURE:
          const bloodPressureIndex = this.alerts.findIndex((item: any) => item.title === ALERT_TITLES.BLOOD_PRESSURE_ALERTS);
          this.alerts[bloodPressureIndex].count++;
          break;
        case OBSERVATION_CODES.TEMPERATURE:
          const temperatureIndex = this.alerts.findIndex((item: any) => item.title === ALERT_TITLES.TEMPERATURE_ALERTS);
          this.alerts[temperatureIndex].count++;
          break;
        case OBSERVATION_CODES.BLOOD_GLUCOSE:
          const bloodGlucoseIndex = this.alerts.findIndex((item: any) => item.title === ALERT_TITLES.BLOOD_GLUCOSE_ALERTS);
          this.alerts[bloodGlucoseIndex].count++;
          break;
        case `${OBSERVATION_CODES.WEIGHT}`:
          const weightIndex = this.alerts.findIndex((item: any) => item.title === ALERT_TITLES.WEIGHT_ALERTS);
          this.alerts[weightIndex].count++;
          break;
        default:
          return;
      }
    }
  }

  fetchDataAndPushToTable = (data: Array<IAlertsTableRowData>) => {
    /**
     * For each entry find the patient details, diagnosis
     * and the program name and map to the row and push to
     * rowData array and update the table;
     */
    if (!data.length) {
      this.rowData = [];
      this.alertsTableData = [...this.rowData];
      this.showTableSpinner = false;
      return;
    }

    const observableArray: Observable<any>[] = [];
    data.forEach((row: IAlertsTableRowData) => {
      if (row.patientId) {
        observableArray.push(
          this.patientService.getPatientProgramDiagnosisGoalsBundle(row.patientId)
            .pipe(
              tap({
                next: (response: {
                  patient: Patient | null;
                  program: CarePlan | null;
                  diagnosis: Condition | null;
                  goals: Goal[]
                } | null) => {
                  if (!response) {
                    return;
                  }
                  const { patient: patientDetails, program: programDetails, diagnosis: diagnosisDetails, goals } = response;
                  if (patientDetails) {
                    row.patientName = filterPatientNameFromPatient(patientDetails);
                  }
                  if (programDetails) {
                    row.programName = filterProgramNameFromCarePlan(programDetails);
                  }
                  if (diagnosisDetails) {
                    row.diagnosis = filterDiagnosisName(diagnosisDetails);
                  }

                  if (goals?.length) {
                    /**
                     * tableData[0].fetchGoals is the template, for each row 
                     * the fetchGoals object represents which all goals needs
                     * to be fetched from backend service. Even this fetchGoal
                     * map is used by table cells to choose whether to make api
                     * calls, still if there is value for corresponding goal in 
                     * row ( meaning there is a task / alert ), then fetchGoal map 
                     * has no effect for that specific goal. eg: if there is a heartRate 
                     * alert and we set fetchGoals.heartRate=true, fetchGoals does 
                     * not have any effect.
                     */
                    row.fetchGoals = { ...tableData[0].fetchGoals };
                    goals.forEach((goal: Goal) => {
                      const code = goal.target?.[0]?.measure?.coding?.[0].code;
                      if (code) {
                        switch (code) {
                          case OBSERVATION_CODES.HEART_RATE:
                            row.fetchGoals.heartRate = true;
                            break;
                          case OBSERVATION_CODES.BLOOD_PRESSURE:
                            row.fetchGoals.bloodPressure = true;
                            break;
                          case OBSERVATION_CODES.TEMPERATURE:
                            row.fetchGoals.temperature = true;
                            break;
                          case OBSERVATION_CODES.SPO2:
                            row.fetchGoals.spo2 = true;
                            break;
                          case OBSERVATION_CODES.BLOOD_GLUCOSE:
                            row.fetchGoals.bloodGlucose = true;
                            break;
                          case `${OBSERVATION_CODES.WEIGHT}`:
                            row.fetchGoals.weight = true;
                            break;
                          default:
                            return;
                        }
                      }
                    })
                  }
                  const rowIndex = this.rowData.findIndex((item: any) => item.patientId === row.patientId);
                  if (rowIndex > -1) {
                    this.rowData[rowIndex] = row;
                  } else {
                    this.rowData.push(row);
                  }
                  this.alertsTableData = [...this.rowData];
                },
                error: (error: any) => {
                  console.error('Fething row data failed : ', error);
                  this.alertsTableData = [...this.rowData];
                }
              }),
              takeUntil(this.unsubscribe$),
            )
        )
      }
    })

    combineLatest(observableArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.showTableSpinner = false;
        },
        error: () => {
          this.showTableSpinner = false;
        }
      });
  }

  handleApiError = (error: any) => {
    console.error("API ERROR : ", error);
    return EMPTY;
  }

  setAlertTaskAndGenerateRows = (response: any) => {
    if (response?.entry) {
      this.alertTasks = response.entry;
    } else {
      this.alertTasks = [];
    }
    this.generateRowDataFromAlertTask();
  }

  handleCardClick = (data: any) => {
    this.selectedAlertType = data.title === ALERT_TITLES.TOTAL_ALERTS ? ALERT_TITLES.ALL_ALERTS : data.title;
    this.rowData = [];
    this.alertsTableData = [...this.rowData];
    this.showTableSpinner = true;

    // to disable adherence alerts
    if (data.title === ALERT_TITLES.ADHERENCE_ALERTS) {
      this.showTableSpinner = false;
      return;
    }

    // unsubscribe program selection timer 
    this.unsubscribeProgramSelection$.next();
    this.unsubscribeProgramSelection$.complete();

    // unsubscribe card selection timer 
    if (this.unsubscribeCardSelection$) {
      this.unsubscribeCardSelection$.next();
      this.unsubscribeCardSelection$.complete();
    }

    // subscribe to card selection timer
    this.unsubscribeCardSelection$ = new Subject<void>();

    timer(0, API_POLLING_DURATION).pipe(
      switchMap(() => {
        // trigger fetch active users API
        this.carePlanService.fetchActiveUsers$.next();

        // reset alerts table data
        this.showTableSpinner = true;
        this.alertsTableData = [];
        this.rowData = [];

        switch (data.title) {
          case ALERT_TITLES.ALL_ALERTS:
            return this.alertService.getAlertCount(1000, this.selectedProgram)
              .pipe(
                tap(this.setAlertTaskAndGenerateRows),
                catchError(this.handleApiError)
              )
          case ALERT_TITLES.TOTAL_ALERTS:
            return this.alertService.getAlertCount(1000, this.selectedProgram)
              .pipe(
                tap(this.setAlertTaskAndGenerateRows),
                catchError(this.handleApiError)
              )
          case ALERT_TITLES.HEART_RATE_ALERTS:
            return this.alertService.getHeartRateAlertsCount(1000, this.selectedProgram)
              .pipe(
                tap(this.setAlertTaskAndGenerateRows),
                catchError(this.handleApiError)
              )
          case ALERT_TITLES.SPO2_ALERTS:
            return this.alertService.getPulseOximeterAlertsCount(1000, this.selectedProgram)
              .pipe(
                tap(this.setAlertTaskAndGenerateRows),
                catchError(this.handleApiError)
              )
          case ALERT_TITLES.BLOOD_PRESSURE_ALERTS:
            return this.alertService.getBloodPressureAlertsCount(1000, this.selectedProgram)
              .pipe(
                tap(this.setAlertTaskAndGenerateRows),
                catchError(this.handleApiError)
              )
          case ALERT_TITLES.TEMPERATURE_ALERTS:
            return this.alertService.getTemperatureAlertsCount(1000, this.selectedProgram)
              .pipe(
                tap(this.setAlertTaskAndGenerateRows),
                catchError(this.handleApiError)
              )
          case ALERT_TITLES.BLOOD_GLUCOSE_ALERTS:
            return this.alertService.getGlucometerAlertsCount(1000, this.selectedProgram)
              .pipe(
                tap(this.setAlertTaskAndGenerateRows),
                catchError(this.handleApiError)
              )
          case ALERT_TITLES.WEIGHT_ALERTS:
            return this.alertService.getWeightAlertsCount(1000, this.selectedProgram)
              .pipe(
                tap(this.setAlertTaskAndGenerateRows),
                catchError(this.handleApiError),
              )
          default:
            return EMPTY;
        }
      }),
      catchError((error: any) => {
        console.error('API Polling Error ', error);
        return EMPTY;
      }),
      takeUntil(this.unsubscribeCardSelection$)
    ).subscribe();
  }

  tableTrackBy(index: number, data: any) {
    return data.patientId
  }

  /**
   * For drag and drop feature
   */
  drop(event: any) {
    moveItemInArray(this.alerts, event.previousContainer.data, event.container.data);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribeProgramSelection$.next();
    this.unsubscribeProgramSelection$.complete();
  }

}

/**
 * Here each goals, eg heartRate etc will be null by default
 * when there is an alert ( Task ), then only the corresponding
 * key should be set with value from its alert. only if there is 
 * an alert set the corresponding alerts value. 
 * 
 * fetchGoals is a map which will be passed to table cells, where
 * each key represents corresponding goal and setting it to true 
 * will fetch the observation. Note that if there is an alert value 
 * set, then setting the corresponding goal in fetchGoals as true
 * does not have any effect.
 */
const tableData: IAlertsTableRowData[] = [
  {
    patientName: '',
    programName: null,
    diagnosis: '',
    patientStatus: 'Need Action',
    heartRate: null,
    spo2: null,
    bloodPressure: null,
    alertUnit: null,
    temperature: null,
    bloodGlucose: null,
    weight: null,
    patientId: '',
    fetchGoals: {
      heartRate: false,
      spo2: false,
      bloodPressure: false,
      temperature: false,
      bloodGlucose: false,
      weight: false,
    }
  }
]